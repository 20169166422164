export enum API_TYPES {
  WPP_FUNDING_PROCESS_API = 'WPP_FUNDING_PROCESS_API',

  WPP_MASTER_DATA_API = 'WPP_MASTER_DATA_API',
}

const developmentApi: Record<API_TYPES, string> = {
  WPP_FUNDING_PROCESS_API: 'https://investmenthub-api-ch-hulk.os-dev.io/api',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  WPP_FUNDING_PROCESS_API: 'https://investmenthub-api-ch-wolverine.os-dev.io/api',
  WPP_MASTER_DATA_API: 'WPP_TEMPLATE_MASTER_DATA_API',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
